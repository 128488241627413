import React from 'react';
import { Link } from 'gatsby';

export const Header = ({ siteTitle, siteDescription }) => (
  <>
    <Link to="/">
      <h1>{siteTitle}</h1>
      <p>{siteDescription}</p>
    </Link>
    <Link to="/about">about</Link>
    <br />
    <Link to="/resume">resume</Link>
    {/*
    <br />
    <Link to="/blog">blog</Link>
    <br />
    <Link to="/coffee">coffee</Link>
    */}
  </>
);
