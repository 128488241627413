import React from 'react';
import styled from 'styled-components';

import { Header } from './Header';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const LayoutStyles = styled.main`
  max-width: 800px;
  margin: 0 auto;
`;

export const Layout = ({ children }) => {
  const { description, title } = useSiteMetadata();
  return (
    <LayoutStyles>
      <Header
        siteDescription={description}
        siteTitle={title}
      />
      {children}
    </LayoutStyles>
  );
};
